import { Link } from 'gatsby';
import Layout from '../components/layout';
import Seo from '../components/seo';
import Service from '../components/service';
import { Button } from '../components/styles/button.styled';
import Heading from '../components/styles/heading.styled';
import Image from '../components/styles/image.styled';
import { Row, Column } from '../components/styles/row.styled';
import { Section } from '../components/styles/section.styled';
import Subtitle from '../components/styles/subtitle.styled';

const IndexPage: React.FC = () => (
  <Layout>
    <Seo />
    <Section className="hero">
      <Row>
        <Column>
          <Heading>
            Hello 👋,
            <br /> I am Honza Pav
          </Heading>
          <p>
            I am an entrepreneur, consultant, maker, father of two and rugby
            player who believes that small and medium businesses and independent
            companies make the world a better place.
          </p>

          <p>
            I help them achieve their goals with the experiences I gained in
            marketing agencies, market research, and my own companies and
            side-projects in the last 15 years.
          </p>
          <a href="#nocode">
            <Button className="hero" variant="outlined">
              Find out more! 👉
            </Button>
          </a>
        </Column>
        <Column>
          <Image
            id="profile"
            src="https://uploads-ssl.webflow.com/5fede57e83f67d755c66b56e/5fedf2f95e05bd80a1a23aaf_honzapav-M.jpg"
          />
        </Column>
      </Row>
    </Section>
    <Service />
    <Section className="emphasis center">
      <Subtitle className="bold">Is it a match?</Subtitle>
      <p>
        If you feel like I can be helpful to your mission, please let me know.
        I'll be more than happy to find out.
      </p>
      <Link to="/contact/">
        <Button variant="outlined">Want to talk?</Button>
      </Link>
    </Section>
  </Layout>
);

export default IndexPage;
