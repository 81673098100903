import styled from 'styled-components';

const Image = styled.img`
  border-radius: 10px;
  box-shadow: 4px 4px 18px 0 rgb(0 0 0 / 10%);

  &&#profile {
    height: 100%;
  }

  @media (max-width: 900px) {
    &&#profile {
      height: auto;
      width: 100%;
    }
  }
`;

export default Image;
