import styled from 'styled-components';

const Title = styled.h2`
  font-weight: 400;
  font-size: 0.85rem;
  color: ${({ theme }) => theme.colors.greenDark};
  text-transform: uppercase;

  span.higlightGreen {
    background-color: ${({ theme }) => theme.colors.greenLight};
    border-radius: 10px;
    padding: 0 10px;
  }
`;

export default Title;
