import { ReactElement } from 'react';
import Title from './styles/title.styled';
import Subtitle from './styles/subtitle.styled';
import { Column, Row } from './styles/row.styled';
import Image from './styles/image.styled';
import { Section } from './styles/section.styled';

const Service = (): ReactElement => (
    <>
        <Section>
            <Row>
                <Column>
                    <Title id="nocode">Low/No-code development and automations</Title>
                    <Subtitle><span className='higlightGreen'>Save</span> you time or money. Or both.</Subtitle>
                    <p>I help small and medium businesses and other organizations with workflow automation. My goal is not only to save them the time and costs associated with the work that machines can do but also to improve the process's output. I make with Integromat, Zapier or Parabola and tools like Asana, Airtable, Pipedrive and all Google services. I also help to build internal tools with Retool, Knack and Ninox.</p>
                </Column>
                <Column>
                    <Image src="https://uploads-ssl.webflow.com/5fede57e83f67d755c66b56e/5fee169e973b9b06931f0def_honzapav-integromat-scenario-invoices.png"></Image>
                </Column>
            </Row>
        </Section>
        <Section >
            <Row className="reversed">
                <Column>
                    <Title>Asana consulting and process design</Title>
                    <Subtitle>Move <span className='higlightGreen'>forward</span> without hustle</Subtitle>
                    <p>I help businesses to improve their teamwork (communication, process and project management) with setting up, implementing and consulting Asana. I can help you decide if Asana is the best tool for your company's needs, show you how real work looks in Asana, helping with setting up a structure or process improvement.</p>
                </Column>
                <Column>
                    <Image src="https://uploads-ssl.webflow.com/5fede57e83f67d755c66b56e/5fee11ef01923a3132f5051d_asana-projektove-rizeni.png"></Image>
                </Column>
            </Row>
        </Section>
        <Section>
            <Row>
                <Column>
                    <Title>Business and brand consulting</Title>
                    <Subtitle>Bring my<span className='higlightGreen'>experience</span> to solve your challenges</Subtitle>
                    <p>In 2013, I co-founded a brand-building workshop called Lovebrand. Since then, I have consulted more than 200 small and medium business on both brand and business strategy. As a maker, I now use my learnings for my project and love to help inspiring companies.</p>
                </Column>
                <Column>
                    <Image src="https://uploads-ssl.webflow.com/5fede57e83f67d755c66b56e/5fee17f1acad018334e8f65f_rozjezdy%20(110).jpg"></Image>
                </Column>
            </Row>
        </Section>
    </>
);

export default Service;